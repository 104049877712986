/*
 *
 * ############################################# Custom JS
 *
 * The Bender Brüder Javascript
 * Handcrafted by WESTWERK GmbH & Co. KG
 * http://www.westwerk.ac
 *
 * Tim Becker | tim@westwerk.ac
 * Dominik Knipprath | dom@westwerk.ac
 *
 * With the power of less
 * for: PBL Milk GmbH Cologne
 */

$(document).ready(function () {

    // on nav hover add and remove a hover class

    $("body:not('.mobile') nav.nav-main").on({
        mouseenter: function () {
            $(this).addClass('hover');
        },

        mouseleave: function () {
            $(this).removeClass('hover');
        }
    });

    $("body.mobile .hamburger").click(function () {
        $('body nav.nav-main').toggleClass('hover');
        $(this).toggleClass('is-active');
    });
    // move sub-nav on startpage in dom

    /*
     $(".nav-sven").append($(".sub-nav.sven"));
     $(".nav-lars").append($(".sub-nav.lars"));
     */

    // Fading background elements

    if ($("body").hasClass("mobile")) {

    }
    else {
        // When navigation is active, fade out other elements
        $('nav.nav-main').not(
            $('.fade-wrapper')).hover(function () {
                $(this).css({zIndex: 1100});
                $(this).addClass('current_element');
                $('#logo, #container #main, #background-static, #background-galerie, #soccermap').not($(this)).addClass('fade-wrapper');
            },
            function () {
                $(this).css("zIndex", "");
                $(this).removeClass('current_element');
                $('#logo, #container #main, #background-static, #background-galerie, #soccermap').removeClass('fade-wrapper');
            });
    }

    // Add the jquery background-cover plugin to some elements

    $('.background-cover').css({backgroundSize: "cover"});


    // add opacity layer over one side on hover on home page

    $(document).ready(function () {
        // Only add blacklyer when on start
        if ($('body').hasClass('neutral')) {
            $("#container").prepend("<div class='blacklayer left'></div><div class='blacklayer right active'></div>");
        }
        else {
        }
    });


    $(".nav-sven a, .sub-nav.sven").on({
        mouseenter: function () {
            //$(".blacklayer.left").toggleClass("active");
        },
        mouseleave: function () {
            //$(".blacklayer.left").toggleClass("active");
        }
    });

    $(".nav-lars a, .sub-nav.lars").on({
        mouseenter: function () {
            $(".blacklayer.right").toggleClass("active");
            $(".blacklayer.left").toggleClass("active");
        },
        mouseleave: function () {
            $(".blacklayer.right").toggleClass("active");
            $(".blacklayer.left").toggleClass("active");
        }
    });

    $(".nav-lars").prepend($("<span class='hashnum'>#8</span>"));
    $(".nav-sven").prepend($("<span class='hashnum'>#5</span>"));

    // Load the Video

    /*
     var videoId = $(".videobox .videoid").text();

     $('#videobox .videobox').html('<iframe src="http://player.vimeo.com/video/' + videoId + '?title=1&amp;byline=1&amp;portrait=1" width="643" height="360" frameborder="0"></iframe>');
     */

    $('#videobox a.closeVideo').bind('click', function (event) {
        event.preventDefault();
        $('#videobox').fadeToggle();
    });


    var lastScrollTop = 0;
    var scrolldelay = $(document).height() - 150 - $(window).height();
    $(window).scroll(function (event) {
        var st = $(this).scrollTop();
        if (st > 200) {
            $('#logo, .change_language').addClass('fixed');
            if (st > 230) {
                $('#logo, .change_language').addClass('transitioned');
                if (st > lastScrollTop) {
                    $('#logo, .change_language').removeClass('out');
                } else {
                    if (st < scrolldelay) {
                        $('#logo, .change_language').addClass('out');
                    }
                }
            }
            lastScrollTop = st;
        } else if (st == 0) {
            $('#logo, .change_language').removeClass('fixed').removeClass('out').removeClass('transitioned');
        }
    });


});




